

import { Component, ElementRef, Inject, OnDestroy, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, NavigationStart, Router, RouterStateSnapshot } from '@angular/router';
import { RouterState } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { environment } from 'common/environments/environment';
import { SearchQuery, UIContentResponse } from 'common/models';
import { AuthState } from 'common/store/auth/auth.state';
import { SetBrand } from 'common/store/branding/branding.actions';
import { FetchUIContent } from 'common/store/content/content.actions';
import { ContentState } from 'common/store/content/content.state';
import { GdprState } from 'common/store/gdpr/gdpr.state';
import { LoginPanelState } from 'common/store/login-panel/login-panel.state';
import { PerformSearch } from 'common/store/search/search.actions';
import { UiState } from 'common/store/ui/ui.state';
import { findChildRoute } from 'common/store/utils/routing.utils';
import { createTitle } from 'common/utils/title-util';
import { isIE11 } from 'common/utils/user-agent';
import { WINDOW } from 'common/window.provider';
import { BrandDetectionService } from 'public/app/services/brand-detection.service';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { filter, first, map, mergeMap, skip, takeUntil, tap } from 'rxjs/operators';
import { AcceptGdpr } from '../../common/store/gdpr/gdpr.actions';
import { NavigationState } from 'common/store/navigation/navigation.state';
import { NavMenu } from 'common/services/navigation-content.service';
import { DataResolverService } from '../../common/content/services/data-resolver.service';
import { ContentService } from 'common/services/content.service';
import { ToastService } from 'common/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { SiteMap } from 'common/content/services/error.service';
import { SEOService } from 'common/services/seo.service';

const NO_SCROLL_CLASS = 'hvac-state-fixed';
const SLIDE_OUT_VISIBLE_CLASS = 'utc-slide-out-visible';
const PANEL_VISIBLE_CLASS = 'hvac-slide-out-visible';
const THEMED_BRANDS = ['carrier', 'bryant', 'totaline'];
const APPZI_SCRIPT_ID = 'appzi-script';
@Component({
    selector: 'utc-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, OnDestroy {
    @Select(UiState.mobileMenuVisible) mobileMenuVisible$: Observable<boolean>;
    @Select(RouterState.state) routerState$: Observable<RouterStateSnapshot>;
    @Select(LoginPanelState.loginPanelVisible) loginPanelVisible$: Observable<boolean>;
    @Select(GdprState.gpdrAccpted) gdprAccepted$: Observable<boolean>;
    @Select(ContentState.header) headerContent$: Observable<UIContentResponse>;
    @Select(AuthState.isLoggedIn) isLoggedIn$: Observable<Boolean>;
    @Select(NavigationState.currentPage) currentPage$: Observable<NavMenu>;

    title = 'utc-hvac-client';
    ngOnDestroy$ = new Subject();
    public loginEnabled = environment.features.auth.login;
    isIcp = environment.brand !== 'Bryant' && environment.brand !== 'Carrier' && environment.brand !== 'Totaline';
    isTotaline = environment.brand === 'Totaline';
    availableRouterState$: Observable<RouterStateSnapshot>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    pageNavigationEvents$: BehaviorSubject<any> = new BehaviorSubject(null);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    favIcon: any;


    constructor(
        private readonly store: Store,
        private readonly brandDetectionService: BrandDetectionService,
        private readonly titleService: Title,
        private readonly renderer: Renderer2,
        private readonly element: ElementRef,
        private router: Router,
        private route: ActivatedRoute,
        private contentService: ContentService,
        private dataResolverService: DataResolverService,
        private toastService: ToastService,
        private translate: TranslateService,
        @Inject(WINDOW) private readonly window: Window,
        private seoService: SEOService
    ) {
    }

    ngOnInit() {
        this.router.events.subscribe((evt) => {
            if (evt instanceof NavigationStart) {
                this.toastService.removeAll();
            }

            if (!(evt instanceof NavigationEnd)) {
                return;
            }

            window.scrollTo(0, 0);

            if (isIE11(this.window.navigator)) {
                this.toastService.add({
                    content: this.translate.instant('IE_BANNER_CONTENT'),
                    theme: 'warning',
                    id: 'ie-banner',
                    closeable: true
                });
            }
        });

        this.contentService.getSiteMap().subscribe((res: SiteMap[]) => {
            const urls = res.concat([
                { Url: '/en/us/' },
                { Url: '/en/us/home' }
            ]);

            this.contentService.siteMap$.next(urls);
        });

        this.dataResolverService.resolveData();

        this.availableRouterState$ = this.routerState$.pipe(
            filter((routerState: RouterStateSnapshot) => Boolean(routerState))
        );

        this.favIcon = document.querySelector('#appIcon');

        if (environment.appziSrc && !window.document.getElementById(APPZI_SCRIPT_ID)) {
            const appziScript = this.window.document.createElement('script');
            appziScript.async = true;
            appziScript.id = APPZI_SCRIPT_ID;
            appziScript.src = environment.appziSrc;
            this.window.document.head.appendChild(appziScript);
        }

        this.availableRouterState$.pipe(
            takeUntil(this.ngOnDestroy$),
            map((routerState: RouterStateSnapshot): ActivatedRouteSnapshot|null => findChildRoute((router: ActivatedRouteSnapshot) => router.data.title, routerState))
        ).subscribe();

        this.store.dispatch(new FetchUIContent());

        this.store.dispatch(new SetBrand(this.brandDetectionService.getBrand()));

        const brand = this.brandDetectionService.getBrand().toLowerCase();

        if (THEMED_BRANDS.includes(brand)) {
            this.renderer.addClass(this.element.nativeElement, brand.toLowerCase());
            this.favIcon.href = `assets/images/${brand.toLowerCase()}-favicon-32x32.png`;
        }
        else {
            this.renderer.addClass(this.element.nativeElement, 'icp');
        }

        if (this.isTotaline) {
            this.router.events.pipe(
                filter((event) => event instanceof NavigationEnd),
                map(() => this.route),
                map((innerRoute) => {
                    while (innerRoute.firstChild) {
                        // eslint-disable-next-line no-param-reassign
                        innerRoute = innerRoute.firstChild;
                    }

                    return innerRoute;
                }),
                filter((innerRoute) => innerRoute.outlet === 'primary'),
                mergeMap((innerRoute) => innerRoute.data)
            ).subscribe((event) => {
                if (event && event['title']) {
                    this.seoService.updateTitle(event['title']);
                }
                this.seoService.updateDescription(event['description']);
                this.seoService.updateKeywords(event['keywords']);
            });
        }
        else {
            combineLatest([this.router.events, this.currentPage$]).subscribe(([_routerEvent, navUrl]) => {
                this.titleService.setTitle(createTitle(this.router.url, navUrl));
            });
        }

        this.routerState$.pipe(
            takeUntil(this.ngOnDestroy$),
            first((routerState) => Boolean(routerState))
        ).subscribe((routerState) => {
            if (!routerState) {
                return;
            }

            const isSearch = routerState.url.startsWith('/search');
            const hasParams = Object.keys(routerState.root.queryParams).length;
            if (isSearch && hasParams) {
                this.store.dispatch(new PerformSearch(routerState.root.queryParams as SearchQuery));
            }
        });

        this.pageNavigationEvents$.pipe(
            takeUntil(this.ngOnDestroy$),
            filter((value) => Boolean(value)),
            skip(1),
            first(),
            tap(() => this.store.dispatch(new AcceptGdpr()))
        ).subscribe();

        this.mobileMenuVisible$.subscribe((mobileMenuVisible) => {
            this.toggleNoScroll(mobileMenuVisible);
        });

        this.loginPanelVisible$.subscribe((loginPanelVisible) => {
            if (loginPanelVisible) {
                this.renderer.addClass(document.documentElement, SLIDE_OUT_VISIBLE_CLASS);
            }
            else {
                this.renderer.removeClass(document.documentElement, SLIDE_OUT_VISIBLE_CLASS);
            }
        });
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }

    toggleNoScroll(property: boolean) {
        if (property) {
            this.renderer.addClass(document.documentElement, NO_SCROLL_CLASS);
            this.renderer.addClass(document.documentElement, PANEL_VISIBLE_CLASS);
        }
        else {
            this.renderer.removeClass(document.documentElement, NO_SCROLL_CLASS);
            this.renderer.removeClass(document.documentElement, PANEL_VISIBLE_CLASS);
        }
    }

    pageRouteActivated(event: string) {
        this.pageNavigationEvents$.next(event);
    }
}

