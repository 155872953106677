import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from 'common/environments/environment';

@Injectable({ providedIn: 'root' })
export class SEOService {
    constructor(private titleService: Title, private meta: Meta) { }


    updateTitle(title: string) {
        this.titleService.setTitle(`${title} | ${environment.brand}`);
    }

    updateDescription(desc: string) {
        this.meta.updateTag({
            name: 'description',
            content: desc
        });
    }

    updateKeywords(keys: string) {
        this.meta.updateTag({
            name: 'keywords',
            content: keys
        });
    }
}
