/* eslint-disable camelcase */
import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Select, Store } from '@ngxs/store';
import { environment } from 'common/environments/environment';
import { ContentHeaderHostDirective } from 'common/content/directives/content-host/content-header-host.directive';
import { DataResolverService } from 'common/content/services/data-resolver.service';
import { ErrorService } from 'common/content/services/error.service';
import { SetContentLoading } from 'common/store/content/content.actions';
import { ContentState } from 'common/store/content/content.state';
import { NavigationState } from 'common/store/navigation/navigation.state';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map, skip } from 'rxjs/operators';
import { ContentFilteringPreferenceType } from '../../../store/content/content.state';
import { ContentHostDirective } from '../../directives/content-host/content-host.directive';
import { ContentComponent, ContentComponentService, EntityTemplate } from '../../services/content-component.service';
import { JumpBarLink } from '../../services/jump-bar.service';
import { SEOService } from 'common/services/seo.service';

const ONE_COLUMN = 'ONE_COLUMN';
const TWO_COLUMN = 'TWO_COLUMN';

const LAYOUT_STYLE_MAP: { [key: string]: string } = {
    one_column: ONE_COLUMN,
    two_column: TWO_COLUMN
};

@Component({
    selector: 'utc-pages',
    templateUrl: './content-page.component.html',
    styleUrls: ['./content-page.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ContentPageComponent implements OnInit {
    @ViewChild(ContentHostDirective, { static: true }) contentHost: ContentHostDirective;
    @ViewChild(ContentHeaderHostDirective, { static: true }) contentHeaderHost: ContentHeaderHostDirective;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Select(NavigationState.navigation) navigation$: Observable<any>;
    @Select(ContentState.contentBrand) brand$: Observable<string>;
    @Select(ContentState.contentFilteringPreference) filterType$: Observable<ContentFilteringPreferenceType>;
    @Select(ContentState.contentLoading) contentLoading$: Observable<boolean>;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    components$: Observable<any>;
    cssAttributes: {} = {};
    jsAttributes: {} = {};
    layoutStyle = '';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    metadataComponent$: Observable<any>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    pageData$: Observable<any>;
    place_in_navigation = '';
    jumpBarData: JumpBarLink[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    videoData$ = new BehaviorSubject<any>({});

    public isTotaline = environment.brand === 'Totaline';
    constructor(
        public dataResolverService: DataResolverService,
        public errorService: ErrorService,
        private readonly contentComponentService: ContentComponentService,
        private readonly componentFactoryResolver: ComponentFactoryResolver,
        private readonly title: Title,
        private readonly store: Store,
        private readonly seoService: SEOService
    ) { }

    ngOnInit() {
        this.pageData$ = this.dataResolverService.pageData$
            .pipe(skip(1))
            .pipe(
                filter((res: string) => Object.keys(res).length > 0)
            );

        this.metadataComponent$ = this.pageData$.pipe(
            map((pageData) => (pageData && pageData.Metadata && pageData.Metadata.pageMetaComponent ? pageData.Metadata.pageMetaComponent : null))
        );

        this.components$ = this.pageData$.pipe(
            map((pageData) => this.contentComponentService.resolveRegions(pageData.Regions))
        );

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.metadataComponent$.subscribe((metadata: any) => {
            if (metadata && metadata.Content) {
                if (metadata.Content.title) {
                    if (this.isTotaline) {
                        this.seoService.updateDescription(metadata.Content.description);
                        this.seoService.updateTitle(metadata.Content.title);
                    }
                    else {
                        this.title.setTitle(metadata.Content.title);
                    }
                }
                if (metadata.Content.cssInclude) {
                    this.cssAttributes = this.createIncludeAtributes(metadata.Content.cssInclude);
                    this.handleCssAttributes(this.cssAttributes);
                }
                if (metadata.Content.jsInclude) {
                    this.jsAttributes = this.createIncludeAtributes(metadata.Content.jsInclude);
                    this.handleJsAttributes(this.jsAttributes);
                }
            }
        });

        this.errorService.errorStatus$.subscribe((errorStatus) => {
            if (errorStatus !== 0) {
                return this.clearViewRefs();
            }
        });

        this.dataResolverService.showNav$.subscribe((showNav) => {
            if (showNav) {
                return this.clearViewRefs();
            }
        });

        combineLatest([this.brand$, this.filterType$]).pipe(skip(1)).subscribe(([_brand, _filterType]) => {
            this.clearViewRefs();
        });

        this.components$.subscribe((components: EntityTemplate[]) => {
            const videoData = components.find((comp) => comp?.entity?.ComponentTemplate?.Title === 'CT_CardCollection-w4-01');
            this.videoData$.next(videoData?.entity);
            const { viewContainerRef } = this.contentHost;
            const headerContainerRef = this.contentHeaderHost.viewContainerRef;
            viewContainerRef.clear();
            headerContainerRef.clear();
            this.store.dispatch(new SetContentLoading(true));
            components.forEach((entityTemplate: EntityTemplate) => {
                if (entityTemplate.template) {
                    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(entityTemplate.template);
                    if (componentFactory.selector === 'hvac-content-header' || componentFactory.selector === 'hvac-color-content-header') {
                        const componentRef = headerContainerRef.createComponent(componentFactory);
                        (<ContentComponent>componentRef.instance).data = entityTemplate.entity;
                        if (entityTemplate.entity && entityTemplate.entity.Content && entityTemplate.entity.Content.links) {
                            this.jumpBarData = entityTemplate.entity.Content.links.$values ? entityTemplate.entity.Content.links.$values : [entityTemplate.entity.Content.links];
                        }
                    }
                    else {
                        const componentRef = viewContainerRef.createComponent(componentFactory);
                        (<ContentComponent>componentRef.instance).data = entityTemplate.entity;
                    }
                }
            });

            return this.store.dispatch(new SetContentLoading(false));
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    createIncludeAtributes(include: any) {
        const attributes: { [key: string]: string } = {};
        if (include.$values) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            include.$values.forEach((attribute: any) => {
                const attributePair = attribute.Fragments[0].split(':');
                attributes[attributePair[0]] = attributePair[1];
            });
        }
        else {
            const attributePair = include.split(':');
            attributes[attributePair[0]] = attributePair[1];
        }

        return attributes;
    }


    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleCssAttributes(cssAttributes: any) {
        if (Object.prototype.hasOwnProperty.call(cssAttributes, 'page_template')) {
            this.layoutStyle = Object.prototype.hasOwnProperty.call(LAYOUT_STYLE_MAP, cssAttributes.page_template) ? LAYOUT_STYLE_MAP[cssAttributes.page_template] : ONE_COLUMN;
        }
    }


    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleJsAttributes(jsAttributes: any) {
        if (Object.prototype.hasOwnProperty.call(jsAttributes, 'place_in_navigation')) {
            this.place_in_navigation = jsAttributes.place_in_navigation;
        }
    }

    clearViewRefs() {
        const { viewContainerRef } = this.contentHost;
        const headerContainerRef = this.contentHeaderHost.viewContainerRef;
        viewContainerRef.clear();
        headerContainerRef.clear();
    }
}
