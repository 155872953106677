import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

export interface Option {
    name: string;
    value: string;
    disabled?: boolean;
    usageType?: (string)[] | null;
}

export enum SelectionSortDirection {
    ASCENDING = 'asc',
    DESCENDING = 'desc',
}

const DEFAULT_EMPTY_VALUE = {
    name: '',
    value: ''
};

@Component({
    selector: 'utc-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SelectComponent implements AfterViewInit {
    @Input() selectId: string;
    @Input() control: string;
    @Input() group: UntypedFormGroup;
    @Input() defaultOption?: string;
    @Input() multiple: boolean = false;
    @Input() variant: 'primary' | 'default' = 'default';
    @Input() size: 'default' | 'small' = 'default';
    @Input() shadow: 'light' | 'default' | 'none' = 'default';
    @Input() sort: SelectionSortDirection;
    @Input() required: boolean;
    @Input() placeholder: string;
    @Input() error: boolean;
    @Input() errorMessage: string | null;
    @Input() infoMessage: string;
    @Input() disabled: boolean | false;
    @Output() onChange: EventEmitter<Option[]> = new EventEmitter();

    isOpen: boolean;
    options$ = new BehaviorSubject<Option[]>([]);

    public minimizeLabel: boolean;
    public valueText: string;
    public defaultEmptyVal: { name: string, value: string } = DEFAULT_EMPTY_VALUE;

    constructor(
        private cdr: ChangeDetectorRef
    ) {
    }

    get options() {
        return this.options$.getValue();
    }

    @Input()
    set options(value: Option[]) {
        if (this.sort) {
            let sortedOptions = value?.slice().sort((valueA, valueB) => valueA.name.toLowerCase().localeCompare(valueB.name.toLowerCase()));
            if (this.sort === SelectionSortDirection.DESCENDING) {
                sortedOptions = sortedOptions?.slice().reverse();
            }
            this.options$.next(sortedOptions);

            return;
        }

        this.options$.next(value);
    }

    ngAfterViewInit() {
        this.defaultEmptyVal = {
            name: this.placeholder,
            value: ''
        };
        this.minimizeLabel = this.group.controls[this.control].value !== '';
        this.valueText = this.setValueText(this.group.controls[this.control].value, this.multiple);

        this.cdr.detectChanges();

        this.group.controls[this.control].valueChanges.subscribe((change: string) => {
            this.minimizeLabel = change !== '';

            this.valueText = this.setValueText(change, this.multiple);
            this.cdr.detectChanges();
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setValueText(value: any, multiple: boolean) {
        const controlValue = value;
        this.onChange.emit(controlValue);

        if (multiple) {
            if (
                (this.group.controls[this.control].value.length === 1 &&
                this.group.controls[this.control].value[0].value === '') || (this.group.controls[this.control].value === '')
            ) {
                this.minimizeLabel = false;

                return '';
            }

            return Object.keys(controlValue).length ?
                controlValue.filter((item: Option) => item.value && item.value !== '').map((item: Option) => item.name).filter(Boolean).join(',') :
                '';
        }

        if (controlValue && controlValue[0]?.value === '') {
            this.minimizeLabel = false;

            return '';
        }

        return controlValue && controlValue[0] ? controlValue[0].name || controlValue : '';
    }

    handleClick(close?: boolean) {
        if (this.disabled) {
            return;
        }

        if (close) {
            this.isOpen = false;
        }
        else {
            this.isOpen = !this.isOpen;
        }

        this.cdr.detectChanges();
    }
}
